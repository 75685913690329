
import { Component, Vue, Prop } from 'vue-property-decorator';
import axios from 'axios';

@Component({
  components: {},
})
export default class JnCookie extends Vue {
  html = '';

  async mounted() {
    const response = await axios.get('cookie.html');
    this.html = response.data;
    console.log('HTML: ' + this.html);
  }
}
